*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App input {
  border: none;
}
#canvas {
  background-color: white;
}
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.absolute-toolkit {
  width: 100vw;
  display: grid;
  justify-items: center;
  position: fixed;
  bottom: 10;
}
.toolkit {
  z-index: 100;
  /* background-color: rgb(231, 230, 230); */
  box-shadow: 0 4px 16px -4px #cccccc;
  border: 1px solid #c8c8c8;
  height: 40px;
  color: black;
  width: 340px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
  column-gap: 10px;
  padding: 0 5px;
  border-radius: 5px;
}
.item {
  background: whitesmoke;
  height: 30px;
  width: 30px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  font-family: sans-serif;
}

.clear {
  justify-self: end;
  width: 50px;
}
.svg-inline--fa {
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 600px) {
  .github {
    display: grid;
  }
  .toolkit {
    width: 100vw;
    grid-template-columns: repeat(8, 1fr);
  }
}
@media only screen and (min-width: 600px) {
  .github {
    display: grid;
  }
  .toolkit {
    grid-template-columns: repeat(8, 1fr);
  }
}
.br {
  border: 1px solid #c8c8c8;
}
